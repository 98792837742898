<template>

<div class="games">

	<div class="card" v-tooltip="'Click to select'" v-for="game in $_.sortBy(match.games, 'name')" :key="game.id" v-on:click="$emit('select', game.id)">

		<div class="card-image" :style="{backgroundImage: 'url(https://geekgroup.app/api/games/avatar/' + game.id + ')'}"></div>
		<div class="card-name">
			<div class="card-name-title">{{ game.name }}</div>
		</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['match'],

}

</script>

<style scoped>

.games {
	display: flex;
	flex-wrap: wrap;
}

.card {
	border-radius: 12px;
	width: calc(25% - 6px);
	margin-right: 8px;
	margin-bottom: 8px;
	height: 64px;
	border: 1px solid #eee;
	background-color: #fff;
	cursor: pointer;
	flex-direction: row;
	display: flex;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	transition: all 100ms linear;
}

.card:hover {
	transform: scale(1.05);
}

.is-tablet .card {
	width: calc(33% - 3px);
}

.is-mobile .card {
	width: 100%;
	margin-right: 0px;
}

.is-desktop .card:nth-child(4n) {
	margin-right: 0px;
}

.is-tablet .card:nth-child(3n) {
	margin-right: 0px;
}

.card.is-empty {
	border-width: 0px;
	box-shadow: none;
	background-color: rgba(175, 90, 90, 0.1);
}

.card-image {
	height: 64px;
	width: 64px;
	flex-shrink: 0;
	background-size: cover;
	border-radius: 12px 0px 0px 12px;
	background-position: 50% 50%;
	border-right: 1px solid #eee;
	font-size: 24px;
	text-align: center;
	color: #ddd;
	line-height: 64px;
}

.card-name {
	padding: 10px;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.card-name-title {
	color: #333;
	font-weight: 400;
	font-size: 14px;
}

.card-name-subtitle {
	color: #707070;
	font-size: 14px;
	margin-top: 2px;
}

.card-empty {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 20px;
	font-weight: 500;
	text-align: center;
	width: 100%;
	padding: 20px;
	color: rgba(0, 0, 0, 0.15);
}

.card-actions {
	display: flex;
	flex-direction: row;
	padding-right: 10px;
	flex-shrink: 0;
}

</style>
